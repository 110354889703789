img.banner {
  width: 100%;
  height: 170px;
  padding-bottom: 20px;
}

.row-price {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #999;
}
