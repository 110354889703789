* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

body {
  overflow-x: hidden;
}

p {
  margin-bottom: 0 !important;
}

h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.z-index-1 {
  z-index: 1;
}

.input-register fieldset {
  top: 0;
}

pre,
p.ws {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  overflow: hidden;
}

p.basic {
  font-size: 13px;
  line-height: normal;
  color: #666666;
}

a {
  text-decoration: underline !important;
  color: #666666 !important;
}

button:focus {
  outline: 0 !important;
}

.container-body {
  max-width: 500px;
  margin: auto;
}

.margin-main {
  padding-bottom: 4rem;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-50 {
  font-size: 50px !important;
}

/* Medium */
.fw-500 {
  font-weight: 500 !important;
}

/* Semi Bold */
.fw-600 {
  font-weight: 600 !important;
}

/* Bold */
.fw-700 {
  font-weight: 700 !important;
}

/* Extra Bold */
.fw-800 {
  font-weight: 800 !important;
}

/* Black */
.fw-900 {
  font-weight: 900 !important;
}

.w-inherit {
  width: inherit !important;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-55 {
  width: 55%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100p {
  width: 100%;
  max-width: 100px;
}

.w-fit {
  width: fit-content;
}

.w-unset {
  width: unset;
}

.h-100 {
  height: 100%;
}

.h-min-full {
  min-height: 100vh;
}

.h-inherit {
  height: inherit !important;
}

.h-50vh {
  min-height: 50vh;
}

.h-55vh {
  min-height: 55vh;
}

.h-70vh {
  min-height: 70vh;
}

.divider-dotted {
  border-top: 2px dotted #cccccc;
}

.divider-dashed {
  border-top: 1px dashed #cccccc;
}

.br-8 {
  border-radius: 8px !important;
}

.br-unset {
  border-radius: unset !important;
}

.pt10 {
  padding-top: 10px;
}

.vertical-middle {
  vertical-align: middle !important;
}

.container-center-basic {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.color-primary {
  color: #ef6431 !important;
}

.color-primary-dark {
  color: #4f2110 !important;
}

.color-secondary {
  color: #666666 !important;
}

.color-secondary-dark {
  color: #22343c !important;
}

.color-secondary-light {
  color: #edf4f6;
}

.gray-1 {
  color: #333333 !important;
}

.gray-2 {
  color: #4c4c4c !important;
}

.gray-3 {
  color: #666666 !important;
}

.gray-4 {
  color: #999999 !important;
}

.gray-5 {
  color: #b2b2b2 !important;
}

.gray-6 {
  color: #cccccc !important;
}

.gray-7 {
  color: #e5e5e5 !important;
}

.gray-8 {
  color: #f2f2f2 !important;
}

.bg-gray-6 {
  background-color: #cccccc !important;
}

.bg-gray-7 {
  background-color: #e5e5e5 !important;
}

.color-white {
  color: white !important;
}

.background-desktop {
  min-height: 100vh;
  background-color: #f2f2f2;
  position: relative;
}

.container-center-desktop {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cont-center {
  display: grid;
  place-items: center;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.text-underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.arrow-prev,
.arrow-next {
  opacity: 0;
  transition: opacity 0.5s;
}

.arrow-prev:hover,
.arrow-next:hover {
  opacity: 0.89;
}

.arrow-prev {
  margin-right: -25px;
}

.arrow-next {
  margin-left: -25px;
}

.scroll-menu-arrow {
  z-index: 2;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.menu-item-wrapper:focus {
  outline: none;
}

.ellipsis1row {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis2row {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 32px;
  margin: 0 auto;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.hide-arrow::-webkit-outer-spin-button,
input.hide-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.hide-arrow[type="number"] {
  -moz-appearance: textfield;
}

.hide-arrow-inside input::-webkit-outer-spin-button,
.hide-arrow-inside input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-arrow-inside input[type="number"] {
  -moz-appearance: textfield;
}

.is-link:hover {
  cursor: pointer;
}

.bg-primary-system {
  background-color: #ef6431 !important;
}

.bg-secondary-system {
  background-color: #669eb5 !important;
}

.bg-blue-8 {
  background-color: #edf4f6;
}

.container-content-mobile-full {
  display: flex;
  flex-direction: column;
  height: 84vh;
}

.font-family {
  font-family: Montserrat !important;
}

.textInputResultGoalKamu {
  background-color: #999;
  border-radius: 4px;
  /* background-color: #fdefea; */
}

.textInputResultGoalKamu input {
  padding: 9px 0 9px 6px;
}

.textInputResultGoalKamu fieldset {
  border-color: #ccc !important;
}

.container-content-mobile {
  display: flex;
  flex-direction: column;
}

.container-row {
  display: flex;
  flex-direction: row;
}

.default-py-button {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.default-py-button.small {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.default-px-button.small {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.card-custom {
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
  /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
     */
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
}

.shadow-card {
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
}

@media (min-width: 769px) {
  .shadow-md-card {
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
  }
}

.no-shadow {
  box-shadow: unset !important;
  -moz-box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}

.rounded-1 {
  border-radius: 4px !important;
}

.rounded-2 {
  border-radius: 8px !important;
}

.rounded-3 {
  border-radius: 6px !important;
}

.border-bottom-custom {
  border-bottom: 2px solid #e5e5e5 !important;
}

.temp-card {
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1) !important;
}

.box-selfie {
  margin-top: -100px;
}

.padding-button {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.flex {
  flex: 1;
}

.hide-outline:focus {
  outline: none;
}

.default-dialog {
  min-width: 375px;
}

@media (min-width: 500px) {
  .container-body {
    max-width: 600px;
  }
}

@media (min-width: 769px) {
  .card-custom {
    padding: 1.5rem;
    box-shadow: unset;
    -moz-box-shadow: unset;
    -webkit-box-shadow: unset;
  }
}

.basic {
  font-size: 13px;
  color: #666;
  font-family: "Montserrat", sans-serif !important;
}
