.active-change {
  /* animation: out 0.2s ease-out, in 0.2s ease-in 0.2s; */
  animation: inout 0.5s ease-in-out alternate forwards;
}

/* @keyframes out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

@keyframes inout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
