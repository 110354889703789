.sidebar {
  width: 220px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  border-right: 1px solid #e8dcdc;
}

.section {
  width: 100%;
  right: 0;
  top: 0;
  position: absolute;
  background-color: #f2f2f2;
  min-height: 100vh;
}

.section.with-sidebar {
  width: calc(100% - 220px);
}

.menu-icon {
  width: 22px;
}

.logo {
  height: 35px;
}

.header {
  background-color: #ef6431;
  position: fixed;
  width: inherit;
  z-index: 1;
}

.content {
  padding-bottom: 50px;
  padding-top: 64px;
  max-width: 1146px;
  padding-bottom: 100px;
}

.menu {
  background-color: #fff;
  transition: background-color 0.3s;
  position: relative;
}

.menu:hover,
.menu.active {
  background-color: #f8fafb;
  cursor: pointer;
}

.menu.active div p,
.menu:hover div p {
  color: #ef6431;
}

.menu.active::before {
  content: "";
  display: inline-block;
  background-color: #ef6431;
  transition: background-color 0.3s;
  width: 4px;
  height: 100%;
  position: absolute;
}

.menu::before {
  content: "";
  display: inline-block;
  background-color: #fff;
  transition: background-color 0.3s;
  width: 4px;
  height: 100%;
  position: absolute;
}

.menu:hover::before {
  background-color: #ef6431;
}